<template>
  <div>
    <div
      class="p-base bg-dark gradient text-center text-light grid place-items-center rounded md:min-h-[16.5rem]"
      :class="{
        '!p-base-1/2': $route.path.includes('vacatures'),
      }"
    >
      <div class="md:max-w-[32rem]">
        <!-- Image -->
        <!-- <NuxtPicture
        v-if="block.image && block.image.data"
        :provider="block.image.data.attributes.provider"
        :src="block.image.data.attributes.hash"
        :width="block.image.data.attributes.width"
        :height="block.image.data.attributes.height"
        :sizes="
          block.settings?.image?.sizes ??
          'xs:100vw sm:100vw md:50vw lg:33vw xl:33vw xxl:33vw'
        "
        :preset="block.settings?.image?.preset"
        :quality="block.settings?.image?.quality"
        :modifiers="block.settings?.image?.modifiers"
        :preload="block.settings?.image?.preload"
        :loading="block.settings?.image?.loading ?? 'lazy'"
        :alt="block.image?.data.attributes.alternativeText"
        :img-attrs="{
          class: 'object-cover h-full w-full',
        }"
        class="overflow-hidden aspect-tv rounded xl:col-span-2"
      /> -->
        <!-- Text -->
        <!-- Show CMS text if available -->
        <RichText :text="text ?? []">
          <template #empty>
            <div class="fk-prose">
              <h2>
                {{ $t("cta.default.title") }}
              </h2>
              <!-- eslint-disable vue/no-v-html -->
              <p
                v-if="!$route.path.includes('vacatures')"
                v-html="ctaDefaultText"
              ></p>
            </div>
          </template>
        </RichText>

        <!-- Button(s) -->
        <!-- Show CMS button(s) if available -->
        <SiteButtons
          v-if="buttons && Array.isArray(buttons) && buttons.length > 0"
          :buttons="buttons"
        />

        <!-- Else show the default button -->
        <NuxtLink v-else :to="localePath('/job-alerts')" class="btn mt-4">
          {{ $t("cta.default.btn") }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { BlockCTA } from "@flyingkiwi/nuxt-components";

const localePath = useLocalePath();
const { t } = useI18n();

const ctaDefaultText = computed(() => {
  return t("cta.default.text", { strong: `<strong>Job Alert</strong>` });
});

defineProps<BlockCTA>();
</script>

<style lang="css" scoped>
h2,
:deep(h2) {
  @apply text-[clamp(1.5rem,3vw,1.875rem)];
}
</style>
